import "./SidebarComponent.css";

import React from "react";
import { Sidebar, Menu, MenuItem, useProSidebar, sidebarClasses, SubMenu } from 'react-pro-sidebar';
import { ConnectWallet, useAddress } from "@thirdweb-dev/react";
import { IoMdMenu } from "react-icons/io";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { gql, useQuery, useApolloClient } from "@apollo/client";
import { GET_MY_PROJECTS, GET_USER_COMMUNITIES } from '../../queries';
import { useEffect, useState } from "react";
import { projectRealtimeDb } from "../../firebase/config";
import communityDefaultAvatar from '../../images/community_avatar_default.png'

const SubMenuItemStyles = {
  fontFamily: 'SFProBold',
  fontWeight: 800,
  fontSize: 14,
  // color: '#B8BED3',
  // backgroundColor: '#202020',
  backgroundColor: '#262626',
  textShadow: '1px 1px 2px black',
  button: {
    '&:hover': {
      backgroundColor: '#181818',
      color: 'white'
    },
  },
}

const MenuComponent = ({ item, community, setSelected, parentSelected, selectedTab, setSelectedTab }: any) => {
  const [name, setName] = useState('')
  const [img, setImg] = useState(communityDefaultAvatar)

  useEffect(() => {
    if (item?.project?.id) {
      getProjectDetails(item?.project?.id)
    } else if (item.id) {
      setName(item.name)
      getProjectDetails(item?.id)
    }
  }, [])



  const getProjectDetails = async (projectId: string) => {
    if (projectId)
      await projectRealtimeDb
        .ref()
        .child("xp")
        .child("communities")
        .child(projectId)
        .once("value")
        .then((snapshot) => {
          if (snapshot.exists()) {
            let name = snapshot.val().name;
            let imageURI = snapshot.val().imageURI;
            name && setName(name)
            imageURI && setImg(imageURI)
          }
        })
  }

  let dynamicProps = {
    "component": community ? <Link to={`community/${item?.project?.id}`} /> : <a href={`https://dashboard.huddln.io/project/${item?.id}`} target="_blank" rel="noopener noreferrer" />
  };


  const onClickHandler = () => {
    if (community) {
      setSelected('community')
      setSelectedTab(name)
    }
  }

  return (
    <MenuItem
      rootStyles={{
        ...SubMenuItemStyles, color: parentSelected && selectedTab === name ? 'white' : '#B8BED3'
      }}
      onClick={onClickHandler}
      {...dynamicProps}
    >
      <section className="menu-item">
        <img
          src={img}
          height={18}
          width={18}
          alt=''
          style={{
            borderRadius: '50%',
            marginRight: 20,
            objectFit: 'cover',
            imageRendering: 'pixelated'
          }}
          className="side-bar-menu-bubble"
        />
        {name}
      </section>
    </MenuItem >
  )
}

export default React.memo(MenuComponent)