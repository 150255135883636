import "./CommunityRedemption.css";
import 'react-toastify/dist/ReactToastify.css';

import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DisplayNftType from "../../components/DisplayNftType/DisplayNftType";
import RedemptionCard from "../../components/RedemptionCard/RedemptionCard";
import Lottie from "lottie-react";
import bonfire from "../../images/bonfire.json";
import Pagination from "../../components/Pagination/Pagination";
import { projectRealtimeDb } from "../../firebase/config";
import { Web3Context } from "../../context/Web3Context";
import { capitalize, getMimeType } from '../../functions/myFunctions'
import { useAddress, useNetworkMismatch } from "@thirdweb-dev/react";
import { useApolloClient } from "@apollo/client";
import { GET_TOTAL_SCORE, GET_ROWS_USER } from "../../queries";
import { ToastContainer, toast } from 'react-toastify';
import shining from '../../images/shining.json'
import polygonIcon from '../../images/polygon-logo.png'
import checkmark from '../../images/Checkmark_anim.json'
import error from '../../images/error.json'
import Marquee from "react-fast-marquee";
import UserAvatar from "../../components/UserAvatar/UserAvatar";
import { ImSpinner8 } from "react-icons/im";
import { setMaxIdleHTTPParsers } from "http";

export default function CommunityRedemption() {
  const address = useAddress();
  const isMismatched = useNetworkMismatch();

  const { projectId, redemptionId } = useParams();
  const {
    purchase1155,
    earn1155,
    purchase721,
    earn721
  } = useContext(Web3Context);
  const client = useApolloClient();
  const navigate = useNavigate();
  const [redemptionChangeTime, setRedemptionChangeTime] = useState(Date.now());
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [isRunningMount, setIsRunningMount] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const [currenrRedeemable, setCurrentRedeemable] = useState({})
  const [allRedeemables, setAllReedemables] = useState([])
  const [data, setData] = useState({});
  const [communtyName, setCommunityName] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [initAmount, setInitAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [xp, setXp] = useState(0);
  const [scoreType, setScoreType] = useState('');
  const [isPurchaseable, setIsPurchaseable] = useState(false);
  const [alreadyEarned, setAlreadyEarned] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [allXp, setAllXP] = useState('');
  const [tokenType, setTokenType] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [src, setSrc] = useState("");
  const [srcType, setSrcType] = useState("");
  const [collectors, setCollectors] = useState([])
  // btn
  const [progressBar, setProgressBar] = useState(0);
  const [progressBarAnim, setProgressBarAnim] = useState(1);
  const [btnTxt, setBtnTxt] = useState("");
  const [btnState, setBtnState] = useState("");
  const [progressBarComplete, setProgressBarComplete] = useState(false);
  const [userXp, setUserXp] = useState(0);
  const [mediaHover, setMediaHover] = useState(false);
  const [displayXp, setDisplayXp] = useState(['ALL:0 XP'])
  const [timeDone, setTimeDone] = useState(false);
  const [xpCheckFail, setXpCheckFail] = useState(false);

  /**
   * ATOMIC MOUNT STATE, update everythign a specific order of dependencies. Helps to organize update and enforce order.
   */
  const mountData = async () => {
    try {
      setIsRunningMount(true);

      // RESET DEFAULT STATES
      setUserXp(0);
      setProgressBarComplete(false)
      setProgressBarAnim(.5)
      setProgressBar(0)
      setAlreadyEarned(false)
      setBtnState('')
      setUserXp(0)
      setBtnTxt('Calculating XP...')
      setTimeDone(false)

      //solo done in parallel for performance, this quuery is non blocker to groupA promises
      getListed(projectId, redemptionId).then((list) => setAllReedemables(list))//PARALLEL GROUP GET OTHER ITEMS LISTED FROM CONTRACT
      getProjectDetails(projectId).then((details) => setCommunityName(details))

      //GET DATA IN ORDER OF REQ, 
      let promiseGroupA = await Promise.allSettled([
        getListedRedemption(redemptionId),
        getUserXPData(address, projectId),
        hasRedemptionBeenEarned(redemptionId, address)
      ])

      const redemptionItem = (promiseGroupA[0] as any).value;//PARALLEL GROUP A - GET PAGE ITEM INFO FROM CONTRACT
      const userXPData = await (promiseGroupA[1] as any).value;//PARALLEL GROUP GET USER XP DATA ABOUT USER
      const hasBeenEarned = (promiseGroupA[2] as any).value;
      const nftData = await getNFTData(redemptionItem.contractAddress, redemptionItem.token);

      //nft states
      setName(nftData?.name);
      setDescription(nftData?.description);
      setTokenType(nftData?.tokenType);
      setContractAddress(nftData?.contractAddress);
      setSrcType(nftData?.mediaType);
      setSrc(nftData?.uri);
      setLoaded(true);//set NFT data loaded for view

      //redemption states
      setIsPurchaseable(redemptionItem?.requiresPurchase);
      setBalance(redemptionItem?.amount);
      setInitAmount(redemptionItem?.initialAmount);
      setScoreType(redemptionItem?.scoreType);
      setXp(redemptionItem?.points);

      //SET USER XP INFORMATION
      userXPData?.totalScore && setDisplayXp(formatXPForDisplay(userXPData?.scores, userXPData?.totalScore));
      await setupButtonState(redemptionItem, hasBeenEarned, userXPData);
      // console.log('asdf')
      setIsRunningMount(false)


    } catch (err) {
      setIsRunningMount(false)
      console.log(err)
    }

  }
  /**
   * Force order of remoutns, fixes race condition if you click fast on new item load.
   * @param retry 
   * @returns 
   */
  const forceWaitTryMount = async (retry: any) => {
    if (retry == 5) return;
    if (!isRunningMount) {
      await mountData();
      return;
    }
    else {
      setTimeout(async () => {
        await forceWaitTryMount(retry + 1)
      }, 100)
    }
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    // if (!isRunningMount) mountData();
    forceWaitTryMount(0);
  }, [redemptionId, address, redemptionChangeTime])

  const getXp = async () => {
    let promiseGroupA = await Promise.allSettled([
      getListedRedemption(redemptionId),
      getUserXPData(address, projectId),
      hasRedemptionBeenEarned(redemptionId, address)
    ])

    const redemptionItem = (promiseGroupA[0] as any).value;//PARALLEL GROUP A - GET PAGE ITEM INFO FROM CONTRACT
    const userXPData = await (promiseGroupA[1] as any).value;//PARALLEL GROUP GET USER XP DATA ABOUT USER
    const hasBeenEarned = (promiseGroupA[2] as any).value;

    userXPData?.totalScore && setDisplayXp(formatXPForDisplay(userXPData?.scores, userXPData?.totalScore));
    await setupButtonState(redemptionItem, hasBeenEarned, userXPData);
  }

  useEffect(() => {
    if (xpCheckFail && address) {
      getXp()
    }
  }, [xpCheckFail, address])

  /**
   * Check if the same person who started this listing still have this item in their account, if not they may have listed it in another listing and already sold out.
   * or they may have sold it somewhere else, only way to know if this listing is still good, is if they still have at least one in their account.
   */
  const checkIfListingIsStillValid = (item: any) => {

  }

  /**
   * 
   * @param redemptionItem 
   * @param userXpData 
   */
  const setupButtonState = async (redemptionItem: any, hasEarned: any, userXpData: any) => {
    if (!address) {
      return
    }
    let timerID;
    // if (isAnimationTimeoutDone) {
    //   clearTimeout(timeoutFunc)
    // }
    clearTimeout(timerID)
    let useXP = 0;
    userXpData?.scores?.forEach((scoreObject: any) => {
      if (scoreObject?.scoreType == redemptionItem.scoreType) useXP = (scoreObject.points).toString();
    })
    // const useXp = userXpData?.scores.
    //setAllXP(useXP)

    if (!redemptionItem.requiresPurchase && hasEarned) {
      setAlreadyEarned(true);
      return redeemHandlerBtn('Already Earned', 'earned');
    } else if (redemptionItem.amount == 0) {
      setAlreadyEarned(true);
      return redeemHandlerBtn('Sold Out!', 'earned');
    }

    setBtnState('')
    const userXpInt = Number(useXP)
    const nftXpInt = Number(redemptionItem.points)


    const percentAnim = userXpInt >= nftXpInt ? 100 : (userXpInt / nftXpInt) * 100
    setUserXp(userXpInt)
    setProgressBarAnim(3)
    setProgressBar(percentAnim)

    let t = ''

    if (nftXpInt >= userXpInt) {
      t = `${nftXpInt - userXpInt} XP Needed`
      setBtnTxt(t)
    } else {
      t = isPurchaseable ? `Buy for ${nftXpInt} XP` : `Claim for ${nftXpInt} XP`
      setBtnTxt(t)
    }
    let timeoutFunctionVar: any = null;

    const setTimeoutFunction = () => {
      clearTimeout(timeoutFunctionVar)
      timeoutFunctionVar = setTimeout(() => {
        setProgressBarComplete(true)
        setTimeDone(true)
      }, 3200);
    };
    setTimeoutFunction()
  }

  const formatXPForDisplay = (scoreBoard: any, totalScore: any) => {
    let ar;
    if (scoreBoard?.length > 0) {
      let ar: any = [`ALL:${totalScore}XP`]

      if (scoreBoard?.length > 0) {
        scoreBoard.forEach((element: any) => {

          const txt = `${element.scoreType.toUpperCase()}:${element.points}`;
          ar.push(txt)
        });
      }
      return ar;
    } else {
      return ['ALL:0 XP']
    }
  }

  const getUserXPData = async (address: any, projectId: any) => {
    try {
      const totalScoreResult = await client.query({
        query: GET_TOTAL_SCORE,
        variables: {
          projectId,
          address: address?.toLowerCase(),
        },
      });
      if (totalScoreResult?.data?.scoreboards[0]) {
        setXpCheckFail(false)
        return totalScoreResult?.data?.scoreboards[0];
      } else {
        setXpCheckFail(true)
        return null
      }
    } catch (error) {
      console.log(error)
    }
  }

  const hasRedemptionBeenEarned = async (redemptionId: any, address: any) => {
    try {
      const earnId = `${redemptionId}_${address?.toLowerCase()}`

      let isEarned = await fetch(
        "https://api.thegraph.com/subgraphs/name/bcaklovi/huddln-redemptions-matic-v1",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
            query ($id: String!)  {
              earnedRedeemables(where: {id: $id}) {
             id
           }
         }
          `,
            variables: {
              id: earnId
            },
          }),
        }
      ).then((resp) => resp.json())
        .catch(err => console.log(err))

      return (isEarned?.data?.earnedRedeemables?.length > 0);
    }
    catch (err) {
      return false;
    }
  }
  const getProjectDetails = async (id: any) => {
    if (id)
      return await projectRealtimeDb
        .ref()
        .child("xp")
        .child("communities")
        .child(id)
        .once("value")
        .then((snapshot: any) => {
          if (snapshot.exists()) {
            let name = snapshot.val().name;
            if (name) return name;
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
  };

  /**
   * Simple statless/hookless function to get data of listed redemption and return its data.
   * @param redeemableId 
   * @returns 
   */
  const getListedRedemption = async (redeemableId: any) => {
    let { data } = await fetch(
      "https://api.thegraph.com/subgraphs/name/bcaklovi/huddln-redemptions-matic-v1",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
          query {
            redeemables(where:{ redeemableId: "${redeemableId}" }) {
              id
              redeemableId
              projectId
              name
              amount
              initialAmount
              points
              actionName
              scoreType
              requiresPurchase
              creator
              contractAddress
              createdAt
              token
            }
  }
        `,
        }),
      }
    ).then((resp) => resp.json())
    return data.redeemables[0]
  }

  const getListed = async (projectId: any, currentRedemptionId: any) => {
    let first = 5;
    let { data } = await fetch(
      "https://api.thegraph.com/subgraphs/name/bcaklovi/huddln-redemptions-matic-v1",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
          query {
            redeemables(where:{ projectId: "${projectId}" },first: ${first} orderBy: createdAt, orderDirection: desc) {
              id
              redeemableId
              projectId
              name
              amount
              initialAmount
              points
              actionName
              scoreType
              requiresPurchase
              creator
              contractAddress
              createdAt
              token
            }
  }
        `,

        }),
      }
    ).then((resp) => resp.json())
      .catch(err => console.log(err))

    if (data?.redeemables.length > 0) {
      for (var i = 0; i < data?.redeemables.length; i++) {

        if (data?.redeemables[i].redeemableId == redemptionId) {
          break;
        }
      }
      return data.redeemables.filter((redemption: any) => redemption.id != currentRedemptionId)

    }
  };

  /**
   * Pure js function stateless, only return nft data of given tokenid
   * @param contractAddress 
   * @param tokenId 
   * @returns object with nft related data only
   */
  const getNFTData = async (contractAddress: any, tokenId: any) => {
    const response = await fetch(
      `https://polygon-mainnet.g.alchemy.com/nft/v2/83uAJ90PdMxTEMuwg3zIHj9ZG-CXD_rH/getNFTMetadata?contractAddress=${contractAddress}&tokenId=${tokenId}&refreshCache=false`
    );
    const _d = await response.json();
    const { metadata, contractMetadata, contract, media } = _d;

    const uri = metadata?.image?.startsWith("ar://")
      ? `https://arweave.net/${metadata?.image?.slice(5)}`
      : metadata?.imageUri?.startsWith("ipfs://")
        ? `https://huddln.mypinata.cloud/ipfs/${metadata?.image?.slice(7)}`
        : metadata?.image?.startsWith("Qm") ? `https://huddln.mypinata.cloud/ipfs/${metadata?.image}`
          : metadata?.image;

    const mediaType: string = (await getMimeType(uri) as string);
    return ({
      name: metadata?.name,
      mediaType: mediaType,
      description: metadata?.description,
      tokenType: contractMetadata?.tokenType,
      contractAddress: contractAddress,
      uri
    })

  }
  const redeemHandlerBtn = (btnT: string, btnS: string) => {
    setBtnTxt(btnT)
    setBtnState(btnS)
  }

  const redeemHandler = async () => {
    if (!address) {
      toast.error('Connect your wallet to get rewards!');
      return toast.clearWaitingQueue();
    } else if (Number(xp) > Number(userXp)) {
      toast(`Not enough XP in ${scoreType} score type`)
      return toast.clearWaitingQueue();
    } else if (isPurchaseable) {
      if (tokenType === 'ERC721') {
        /*  toast.promise(purchase721(redemptionId, projectId, redeemHandlerBtn), {
            pending: "Buying...",
            success: "Bought!",
            error: "Transaction failed!"
          });*/
        purchase721(redemptionId, projectId, redeemHandlerBtn);
      } else if (tokenType === 'ERC1155') {
        /*
        toast.promise(purchase1155(redemptionId, projectId, redeemHandlerBtn), {
          pending: "Buying...",
          success: "Bought!",
          error: "Transaction failed!"
        });
        */
        purchase1155(redemptionId, projectId, redeemHandlerBtn);
      }
    } else {
      if (tokenType === 'ERC721') {
        /*
        toast.promise(earn721(redemptionId, projectId, redeemHandlerBtn), {
          pending: "Collecting...",
          success: "Collected!",
          error: "Transaction failed!"
        });
        */
        earn721(redemptionId, projectId, redeemHandlerBtn);
      } else if (tokenType === 'ERC1155') {
        /*
        toast.promise(earn1155(redemptionId, projectId, redeemHandlerBtn), {
          pending: "Collecting...",
          success: "Collected!",
          error: "Transaction failed!"
        });
        */
        earn1155(redemptionId, projectId, redeemHandlerBtn);
      }

    }
  }

  const navToCommunityHandler = () => {
    navigate(
      `/community/${projectId}`
    );
  }

  const navTo = (id: any) => {
    setRedemptionChangeTime(Date.now());//Forces refresh even if you tap on same card.

    navigate(
      `/community/${projectId}/${id}`
    );
  }
  const handleWindowSizeChange = () => setWindowWidth(window.innerWidth);
  const handleMediaHoverLeave = () => setMediaHover(false);
  const handleMediaHoverEnter = () => setMediaHover(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(4);

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const shownNfts = allRedeemables.slice(firstPostIndex, lastPostIndex);

  return (
    <div className="community-redemption-container" >
      <meta
        property="og:description"
        key="og:description"
        content={description}
      />
      <meta
        property="og:image"
        key="og:image"
        content={src}
      />

      <div className="community-redemption-container-inner"  >
        <div className="community-redemption-name" >
          <h1 onClick={navToCommunityHandler}>{communtyName}</h1>
        </div>
        <div className="redemption-container-top" >
          <div className="redemption-container-inner" onClick={() => setShowModal(true)}>
            <div className="redemption-container-img"
              style={{
                ...(((srcType == ("mp4" || "mov" || "mpeg" || "video")) && (mediaHover) && (windowWidth > 720)) && { position: 'absolute', left: 0, objectFit: 'contain', maxWidth: '100%', width: '100%', boxShadow: " 0px 0px 60px 20px black" })
              }}
              onMouseEnter={handleMediaHoverEnter} onMouseLeave={handleMediaHoverLeave} >
              <DisplayNftType
                objectFit={mediaHover ? "contain" : "cover"}
                disableControls={!mediaHover}
                type={srcType}
                src={src}
                height='100%'
                width='100%'
                borderRadius='10px'
                loaded={loaded}
              />
            </div>
          </div>
          <div className="redemption-container-inner">
            <div className="redemption-container-description">
              <h3 className="nft-h3">NFT Name</h3>
              <h1 className="nft-name">{name}</h1>
              {description && <>
                <h3 className="nft-h3" >NFT Description</h3>
                <div className="nft-description-contain">
                  <h1 className="nft-description">{description}</h1>
                </div>
              </>}
              <div className="redemption-container-score-info">
                <div className="redemption-container-score-info-inner">
                  <h3 className="nft-h3">NFT Type</h3>
                  <h2 className="nft-name">{tokenType}</h2>
                </div>
                <div className="redemption-container-score-info-inner">
                  <h3 className="nft-h3">Network</h3>
                  <div className="network-contain">
                    <img src={polygonIcon} height={10} alt='' />
                    <h2 className="network-contain-name">Polygon</h2>
                  </div>
                </div>
              </div>
              <hr className="redemption-hr small" />
              <div className="redemption-container-editions" >
                <h1 style={{ color: balance <= 0 ? '#aaa' : 'white' }}>{balance <= 0 ? `${initAmount} Edition${initAmount == 1 ? '' : 's'} Sold Out!` : `Editions Collected: ${initAmount - balance} of ${initAmount}`}</h1>
              </div>
              <div className="redemption-container-score-info">
                <div className="redemption-container-score-info-inner">
                  <h3 className="nft-h3">Score Type</h3>
                  <h1 className="nft-name">{capitalize(scoreType)}</h1>
                </div>
                <div className="redemption-container-score-info-inner">
                  <h3 className="nft-h3">Redemption Type</h3>
                  <h1 className="nft-name">{isPurchaseable ? 'Purchase' : 'Earn'}-Able</h1>
                </div>
              </div>
              <div className="redemption-container-score-info">
                <div className="redemption-container-score-info-inner">
                  <h3 className="nft-h3">{isPurchaseable ? 'Buy for' : 'Minimum Score'}</h3>
                  <h1 className="nft-name">{xp} XP</h1>
                </div>
                <div className="redemption-container-score-info-inner">
                  {collectors?.length > 0 && (
                    <>
                      <h3 className="nft-h3">Collectors</h3>
                      <div className="redemption-container-score-collectors">
                        <div className="redemption-container-score-avatar">
                          {collectors.map((redeemer, i) => (
                            <div style={{ position: "absolute", top: 0, left: i * 30, zIndex: 3 - i }} key={redeemer + i}>
                              <UserAvatar address={redeemer} shadowColor="22px" />
                            </div>
                          ))}
                        </div>
                        {((initAmount - balance) - 3) > 0 && <h3 className="nft-h3">+{(initAmount - balance) - 3} others</h3>}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {Boolean(address) && Boolean(!isMismatched) &&
                <div className="redeem-handler" >
                  {progressBarComplete && (
                    <Lottie
                      animationData={shining}
                      loop={true}
                      style={{
                        position: 'absolute',
                        flexDirection: 'column',
                        top: -50,
                        left: 65,
                        right: 0,
                        bottom: 0,
                        width: 200,
                        height: 200,
                        filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
                      }}
                    />
                  )}
                  {alreadyEarned ? (
                    <div className="redeem-handler-progress-contain">
                      <h1 >{btnTxt}</h1>
                    </div>
                  ) : btnState === 'loading' ? (
                    <div className="redeem-handler-progress-contain" style={{ backgroundColor: "#717285" }}>
                      {/* <img src={loading} alt='' height={14} style={{ marginRight: 10 }} /> */}
                      <ImSpinner8 size={14} color="white" className="redeem-spinner" />
                      <h1>{isPurchaseable ? 'Buying...' : 'Collecting...'}</h1>
                    </div>
                  ) : btnState === 'success' ? (
                    <div className="redeem-handler-progress-contain">
                      <Lottie
                        animationData={checkmark}
                        autoPlay={true}
                        initialSegment={[0, 130]}
                        loop={false}
                        style={{
                          marginLeft: -30,
                          marginRight: -10,
                          width: 56,
                          height: 56,
                        }}
                      />
                      <h1>{isPurchaseable ? 'Bought!' : 'Collected!'}</h1>
                    </div>
                  ) : btnState === 'error' ? (
                    <div className="redeem-handler-progress-contain" onClick={redeemHandler}>
                      <Lottie
                        animationData={error}
                        autoPlay={true}
                        // initialSegment={[0, 130]}
                        loop={false}
                        style={{
                          marginLeft: -10,
                          // marginRight: ,
                          width: 30,
                          height: 30,
                        }}
                      />
                      <h1>Try Again</h1>
                    </div>
                  ) : (
                    <div className="redeem-handler-progress-contain">
                      <div className="redeem-handler-progress" style={{ transition: `all ${progressBarAnim}s ease-out`, width: `${progressBar.toString()}%` }} />
                      {timeDone ? (
                        <h1 onClick={redeemHandler} style={{ color: Number(progressBar) > 70 ? 'black' : 'white' }}>{btnTxt}</h1>
                      )
                        : (
                          <h1 style={{ color: 'white' }}>Calculating XP...</h1>
                        )}
                    </div>
                  )}
                </div>}
            </div>
          </div>
        </div>
        <h1 className="redemption-marque-header">My Scoreboard in this Community</h1>
        <div style={{ width: '100%', position: 'relative', marginTop: 2 }}>
          <Marquee gradient={true} gradientColor={[249, 171, 231]} style={{ borderTopLeftRadius: !address ? "4px" : undefined, borderBottomLeftRadius: !address ? "4px" : undefined }} gradientWidth={100} className="redemption-marquee" speed={40}>
            {!Boolean(address) &&
              <h1 className="redemption-marque-txt item">
                <span className="redemption-marque-txt item" style={{ display: "inline" }}>{"Connect wallet to see your XP"}</span>
              </h1>
            }


            {Boolean(address) && displayXp.map((t, i) => {
              let formattedTitle = (t.split(':')[0].charAt(0).toUpperCase()) + t.split(':')[0].slice(1).toLowerCase();
              let scoreFormatted = parseInt((t.split(':')[1]).split('XP')[0]).toLocaleString()
              if (formattedTitle == "All") {
                formattedTitle = "Total";
              }
              return (
                <h1 key={i} className="redemption-marque-txt item">
                  <span style={{ display: "inline" }}>{formattedTitle + ": "}
                    <span className="redemption-marque-txt-scores item" style={{ display: "inline" }}>{scoreFormatted + " XP"}</span>
                  </span>
                </h1>)

            })}
          </Marquee>
          {address && (
            <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, zIndex: 2 }}>
              <UserAvatar
                size={50}
                shadowColor="black"
                address={address}
                withVerification={false}
                onClick={() => { }}
              />
            </div>
          )}
        </div>
        <hr className="redemption-hr" />
        <div className="other-redemption-container">
          <div className="other-redemption-container-title">
            <h1>Other Redemptions from this Community</h1>
          </div>
          {shownNfts.length > 0 ? (
            <div className="other-redemption-container-map" >
              {shownNfts.map((item: any) =>
                <RedemptionCard
                  item={item}
                  key={item?.id}
                  onClick={navTo}
                  width='180px'
                  style={{
                    marginBottom: 30
                  }}
                  padding="10px"
                />
              )}
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Lottie
                animationData={bonfire}
                loop={true}
                style={{ height: 300 }}
              />
            </div>
          )}
          <Pagination
            total={allRedeemables.length}
            perPage={postPerPage}
            setPage={setCurrentPage}
            currentPage={currentPage}
            enableEnd={false}
            onEndReach={() => { }}
          />
        </div>
      </div>
      {
        showModal && <div onClick={() => setShowModal(false)} style={{ position: 'fixed', display: 'flex', top: 0, left: 0, bottom: 0, right: 0, background: 'rgba(0,0,0,.9)', height: '100%', width: '100%', justifyContent: "center", alignItems: 'center', zIndex: 1000000 }}>
          <div style={{ borderRadius: '10px', overflow: "hidden", objectFit: 'contain' }}>
            <DisplayNftType
              type={srcType}
              src={src}
              height='500px'
              width='500px'
              loaded={loaded}
              objectFit='contain'
            />
          </div>
        </div>
      }
      <ToastContainer
        limit={3}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div >
  )
}
