export const SET_ALL_VERIFIED_COMMUNITIES = 'SET_ALL_VERIFIED_COMMUNITIES'
export const SET_PROJECT_SHOW_TYPE = 'SET_PROJECT_SHOW_TYPE'
export const GET_USER_COMMUNITIES_REDUX = "GET_USER_COMMUNITIES_REDUX";
export const GET_USER_PROJECTS_REDUX = "GET_USER_PROJECTS_REDUX";

export const setAllVerifiedCommunities = (allVerifiedCommunities: any) => {
  return {
    payload: {
      allVerifiedCommunities,
    },
    type: SET_ALL_VERIFIED_COMMUNITIES,
  }
}


export const setProjectShowType = (projectShowType: any) => {
  return {
    payload: {
      projectShowType,
    },
    type: SET_PROJECT_SHOW_TYPE,
  }
}

export const setUserCommunitiesAction = (userCommunities: any) => {
  return {
    payload: {
      userCommunities,
    },
    type: GET_USER_COMMUNITIES_REDUX,
  }
}

export const setUserProjectsAction = (userProjects: any) => {
  return {
    payload: {
      userProjects,
    },
    type: GET_USER_PROJECTS_REDUX,
  }
};
