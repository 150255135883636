import "../styles/ScoreTab.css";

import React, { useState, useEffect } from "react";
import { GET_ROWS, GET_ROWS_ALL } from "../queries";
import ScoreRow from "./ScoreRow";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import UserAvatar from './UserAvatar/UserAvatar';
import crown from "../images/crown.json";
import shining from '../images/shining.json'
import { useApolloClient } from "@apollo/client";
import { Zoom, toast } from 'react-toastify';
import { BsClipboard } from "react-icons/bs";

type ScoreTabProps = {
  projectId: string | undefined;
  scoreType: string;
  open: boolean;
};

const ScoreTab = ({ projectId, scoreType, open }: ScoreTabProps) => {
  const client = useApolloClient();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [displayNum, setDisplayNum] = useState(15);
  const [top3Users, setTop3Users] = useState<any[]>([]);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [showLoadMore, setShowLoadMore] = useState(false);


  const getScoreRows = async (loadMore = false) => {
    let skip = 0
    let first = 15

    if (loadMore) {
      skip = displayNum;
      setDisplayNum((prev) => prev + 15)
      first = 15
    } else {
      setDisplayNum(15)
    }

    let rows: any[] = [];
    let top3Arr: any[] = [];
    let scoreTypeResults;

    if (scoreType === "all") {
      scoreTypeResults = await client.query({
        query: GET_ROWS_ALL,
        variables: {
          projectId,
          first,
          skip
        },
      });

      rows = [...scoreTypeResults.data.scoreboards];
      if (rows.length >= 15) {
        setShowLoadMore(true)
      } else {
        setShowLoadMore(false)
      }
      if (loadMore) {
        setAllUsers([...allUsers, ...rows])
        setLoading(false)
      } else {
        console.log(rows)
        top3Arr = rows.splice(0, 3);
        console.log('jere')
        setTop3Users(top3Arr);
        setAllUsers(rows)
        setLoading(false)
      }

    } else {
      scoreTypeResults = await client.query({
        query: GET_ROWS,
        variables: {
          projectId: projectId,
          scoreTypeName: scoreType,
          first,
          skip
        },
      });
      rows = [...scoreTypeResults.data.scores];
      if (rows.length >= 15) {
        setShowLoadMore(true)
      } else {
        setShowLoadMore(false)
      }
      if (!loadMore) {
        top3Arr = rows.splice(0, 3);
        setTop3Users(top3Arr)
        setAllUsers(rows)
        setLoading(false)
      } else {
        setAllUsers([...allUsers, ...rows])
        setLoading(false)
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    setDisplayNum(15);
    setTop3Users([]);
    setAllUsers([])
    getScoreRows();
  }, [projectId]);



  const navToUser = (a: string) => {
    navigate(
      `/user/${a.toLowerCase()}/${projectId}`
    );
  }

  const copyClipboard = (address: any) => {
    navigator.clipboard.writeText(address);
    toast(`Address copied`, {
      position: "top-center",
      autoClose: 750,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Zoom
    })
  }

  if (!loading) {
    return (
      <div className={open ? "open" : "closed"} >
        <div className="top-3-scores">
          {top3Users.length > 0 && <Lottie
            animationData={shining}
            loop={true}
            style={{

              height: 200,
              position: 'absolute', top: '16%', bottom: '10%', //backgroundColor:'blue',
              filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.1))',
            }}
          />}

          {top3Users && top3Users[1] ? (
            <div
              className="top-score"
              onClick={() => navToUser(top3Users[1].address)
              }
            >
              <div className="best-score-rank">2</div>
              <UserAvatar
                borderWidth="7px"
                enableShadow={true}
                enableInfo={true}
                size={'5em'}
                infoPlacement={'bottom'}
                address={top3Users[1].address}
              />
              <div className="gradient-text">
                {top3Users[1]?.points &&
                  parseInt(top3Users[1]?.points).toLocaleString("en-US")}
                {top3Users[1]?.totalScore &&
                  parseInt(top3Users[1]?.totalScore).toLocaleString(
                    "en-US"
                  )}{" "}
                XP
              </div>
            </div>
          ) : <div className="top-score" />}
          {top3Users && top3Users[0] ? (
            <div
              style={{ position: 'relative' }}
              className="top-score best-score"
              onClick={() => navToUser(top3Users[0].address)}
            >
              <Lottie
                animationData={crown}
                //loop={true}
                style={{
                  height: '35%',
                  backgroundColor: "transparent",
                  position: 'absolute', top: '-40%',
                  filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8))',
                }}
              >
              </Lottie>
              <div className="best-score-rank">1</div>
              <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', zIndex: 250 }}>
                <UserAvatar
                  borderWidth="7px"
                  borderColor="#FFEA01"
                  shadowColor="rgba(255, 234, 1, .3)"
                  enableShadow={true}
                  enableInfo={true}
                  size={'7em'}
                  infoPlacement={'bottom'}
                  address={top3Users[0].address}
                />
              </div>
              <div className="gradient-text">
                {top3Users[0].points &&
                  parseInt(top3Users[0]?.points).toLocaleString("en-US")}
                {top3Users[0]?.totalScore &&
                  parseInt(top3Users[0]?.totalScore).toLocaleString(
                    "en-US"
                  )}{" "}
                XP
              </div>
            </div>
          ) : <div className="top-score" />}
          {top3Users && top3Users[2] ? (
            <div
              className="top-score"
              onClick={() => navToUser(top3Users[2].address)}
            >
              <div className="best-score-rank">3</div>

              <UserAvatar
                borderWidth="7px"
                enableInfo={true}
                enableShadow={true}
                size={'5em'}
                infoPlacement={'bottom'}
                address={top3Users[2].address}
              />
              <div className="gradient-text">
                {top3Users[2].points &&
                  parseInt(top3Users[2]?.points).toLocaleString("en-US")}
                {top3Users[2]?.totalScore &&
                  parseInt(top3Users[2]?.totalScore).toLocaleString(
                    "en-US"
                  )}{" "}
                XP
              </div>
            </div>
          ) : <div className="top-score" />}
        </div>
        <div className="all-score-rows">
          {allUsers &&
            allUsers.map((user, index) => {
              console.log(user)
              return (
                <ScoreRow
                  key={index + 3}
                  projectId={projectId}
                  address={user.address}
                  userInfo={null}
                  points={scoreType === "all" ? user.totalScore : user.points}
                  rank={index + 4}
                />
              )
            })}
        </div>
        {showLoadMore && <div className="load-more-container">
          <button
            onClick={() => getScoreRows(true)}
            id="load-more"
          >
            Load more
          </button>
        </div>}
      </div >
    );
  }

  else {
    return (<></>);
  }
};

export default React.memo(ScoreTab);
