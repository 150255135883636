import { useEffect, useState, createContext } from "react";
import { useSDK } from "@thirdweb-dev/react";

import redemptions721ABI from "../abi/RedemptionsFunctionsExisting721.json";
import redemptions1155ABI from "../abi/RedemptionsFunctions.json";

import { SmartContract } from "@thirdweb-dev/sdk";
import { BaseContract, BigNumber, utils } from "ethers";
import { ApolloClient, InMemoryCache, useApolloClient } from "@apollo/client";
import { GET_PROJECT_REDEEMABLES } from "../queries";

export const Web3Context = createContext<any>(undefined);

export const Web3ContextProvider = ({ children }: any) => {
  const [mounted, setMounted] = useState(false)
  const sdk = useSDK();

  useEffect(() => {
    const getRedeemables = async () => {
      const redeemablesResults = await redeemableClient.query({
        query: GET_PROJECT_REDEEMABLES,
        variables: {
          projectId:
            "0x15524620e34e6a3decd880a102965c6a246db258a26565449ec106b56441a4c3",
        },
      });

      setMounted(true)
    };

    getRedeemables();
  }, []);

  useEffect(() => {
    const loadContracts = async () => {
      const redeemables1155 = await sdk?.getContract(
        "0x2f4d727e018e3ae60db57cDA4B78670A8C44f7fc",
        redemptions1155ABI.abi
      );

      setRedeemables1155Contract(redeemables1155);

      const redeemables721 = await sdk?.getContract(
        "0x10dc5d77097F109677a8aE8cbDCEA63F7934CA9a",
        redemptions721ABI.abi
      );

      setRedeemables721Contract(redeemables721);

      const res1 = await redeemables1155?.call("xpContract");
      const res2 = await redeemables721?.call("xpContract");
    };

    if (sdk) loadContracts();
  }, [sdk]);

  const redeemableClient = new ApolloClient({
    uri: "https://api.thegraph.com/subgraphs/name/bcaklovi/huddln-redemptions-matic-v1",
    cache: new InMemoryCache(),
  });


  const [redeemables1155Contract, setRedeemables1155Contract] = useState<
    SmartContract<BaseContract> | undefined
  >();
  const [redeemables721Contract, setRedeemables721Contract] = useState<
    SmartContract<BaseContract> | undefined
  >();

  const purchase1155 = async (redeemableID: string, projectID: string, redeemHandlerBtn: any) => {
    if (redeemables1155Contract) {
      try {
        redeemHandlerBtn('loading', 'loading')
        let randomUpdateID = BigNumber.from(utils.randomBytes(32))._hex;

        const tx = await redeemables1155Contract.prepare("purchaseRedeemable", [
          randomUpdateID,
          redeemableID,
          projectID,
        ]);

        const resp = await tx.send();
        console.log(resp);
        redeemHandlerBtn('success', 'success')
      } catch (error) {
        throw redeemHandlerBtn('error', 'error')
      }

    }
  };

  const earn1155 = async (redeemableID: string, projectID: string, redeemHandlerBtn: any) => {
    if (redeemables1155Contract) {
      try {
        redeemHandlerBtn('loading', 'loading')
        const tx = await redeemables1155Contract.prepare("earnRedeemable", [
          redeemableID,
          projectID,
        ]);

        const resp = await tx.send();
        console.log(resp);
        redeemHandlerBtn('success', 'success')
      } catch (error) {
        throw redeemHandlerBtn('error', 'error')
      }

    }
  };

  const purchase721 = async (redeemableID: string, projectID: string, redeemHandlerBtn: any) => {
    if (redeemables721Contract) {
      try {
        redeemHandlerBtn('loading', 'loading')
        let randomUpdateID = BigNumber.from(utils.randomBytes(32))._hex;

        const tx = await redeemables721Contract.prepare("purchaseRedeemable721", [
          randomUpdateID,
          redeemableID,
          projectID,
        ]);

        const resp = await tx.send();
        console.log(resp);
        redeemHandlerBtn('success', 'success')
      } catch (error) {
        throw redeemHandlerBtn('error', 'error')
      }
    }
  };

  const earn721 = async (redeemableID: string, projectID: string, redeemHandlerBtn: any) => {
    if (redeemables721Contract) {
      try {
        redeemHandlerBtn('loading', 'loading')
        const tx = await redeemables721Contract.prepare("earnRedeemable721", [
          redeemableID,
          projectID,
        ]);

        const resp = await tx.send();
        console.log(resp);
        redeemHandlerBtn('success', 'success')
      } catch (error) {
        throw redeemHandlerBtn('error', 'error')
      }
    }
  };

  if (!mounted) {
    return <></>
  }


  // if (notConnected) {
  //   return <NotConnected connectWallet={connectWallet} loaded={loaded} />;
  // }

  // if (!web3) {
  //   return (
  //     <Flex
  //       flexDir="column"
  //       bgColor="black"
  //       minH="100vh"
  //       justifyContent="center"
  //       alignItems="center"
  //     >
  //       <Heading color="white" fontSize="xl" mb="2vh">
  //         Loading web3 connection...
  //       </Heading>

  //       <div className="lds-roller">
  //         <div></div>
  //         <div></div>
  //         <div></div>
  //         <div></div>
  //         <div></div>
  //         <div></div>
  //         <div></div>
  //         <div></div>
  //       </div>
  //     </Flex>
  //   );
  // }

  return (
    <Web3Context.Provider
      value={{
        purchase1155,
        earn1155,
        purchase721,
        earn721
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};
