import "./Card.css";

interface Props {
  children: any
}

export default function Card({ children }: Props) {
  return (
    <div className='card-container'>{children}</div>
  )
}
