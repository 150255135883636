import './index.css'
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { ChainId, ThirdwebProvider ,walletConnect,metamaskWallet,coinbaseWallet, safeWallet} from "@thirdweb-dev/react";
import { ProSidebarProvider } from "react-pro-sidebar";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { store } from "./redux/Store";
import { Web3ContextProvider } from './context/Web3Context';
import { Polygon ,Ethereum} from "@thirdweb-dev/chains";
import {
  ThirdwebStorage,
  StorageDownloader,
  IpfsUploader,
} from "@thirdweb-dev/storage";
const activeChainId = ChainId.Polygon;

const defaultOptions: any = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const apolloClient = new ApolloClient({
  uri: "https://api.thegraph.com/subgraphs/name/bcaklovi/huddln-xp-mainnet",
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// Configure a custom ThirdwebStorage instance
const gatewayUrls = {
  "ipfs://": [
    "https://gateway.ipfscdn.io/ipfs/",
    "https://cloudflare-ipfs.com/ipfs/",
    "https://ipfs.io/ipfs/",
  ],
};
const downloader = new StorageDownloader();
const uploader = new IpfsUploader();
const storage = new ThirdwebStorage({gatewayUrls });


root.render(
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <React.StrictMode>
        <ProSidebarProvider>
          <ThirdwebProvider
          supportedWallets={
            [ safeWallet(),
            
              metamaskWallet(),
              coinbaseWallet(),
              walletConnect()]
          }
            storageInterface={storage}
            activeChain={Polygon}
            sdkOptions={{


              gasless: {
                openzeppelin: {
                  relayerUrl:
                    "https://api.defender.openzeppelin.com/autotasks/ed732c59-8e4d-49a6-9895-fda364244215/runs/webhook/72a1a5e9-5b5c-4822-b471-48606e767957/FM5zTHzYq5zqUxgMpyDw5i",
                },
              },
            }}
          >
            <Web3ContextProvider>
              <App />
            </Web3ContextProvider>
          </ThirdwebProvider>
        </ProSidebarProvider>
      </React.StrictMode>
    </Provider>
  </ApolloProvider>
);
