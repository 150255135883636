import React, { useState } from "react";
import "./Modal.css";

interface Props {
  children: any;
  setShow: React.Dispatch<any>
  show: boolean
}

export default function Modal({ children, show, setShow }: Props) {


  const toggleModal = () => {
    setShow(!show);
  };

  if (show) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  return (
    <>
      {show && (
        <div className="modal-qr"  onClick={toggleModal}>
          {/* <div onClick={toggleModal} className="overlay"></div> */}
          <div className="modal-content-qr">
            {children}
          </div>
        </div>
      )}
    </>
  );
}