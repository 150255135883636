import { useState, useEffect } from "react";
import "../styles/Modal.css";
import { IoMdClose } from "react-icons/io";
import loader from "../images/loading3.gif";
import { Dispatch, SetStateAction } from "react";
import { firestoreCheckIfUsernameIsUniqueV2 } from "../utility";
import { useAddress, useSDK } from "@thirdweb-dev/react";
import { ethers } from "ethers";
import EthCrypto from "eth-crypto";
import BackgroundHeader from '../images/updateProfileHeader.png'
const UpdateProfileModal = ({
  open,
  setUpdateProfileOpen,
  onSuccess,
  onFailure
}: {
  open: boolean;
  onSuccess?: any;
  onFailure?: any;
  setUpdateProfileOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const address = useAddress();

  const sdk = useSDK();

  // Modal state
  const [username, setUsername] = useState("");
  const [usernamePreLoaded, setUsernamePreLoaded] = useState(false);
  const [email, setEmail] = useState("");
  const [profileImage, setProfileImage] = useState<File>();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formError, setFormError] = useState("");
  const [usernameIsUnique, setUsernameIsUnique] = useState<boolean>(false);

  const checkForUniqueness = async (username: any) => {
    const isUnique = await firestoreCheckIfUsernameIsUniqueV2(username);
    //console.log(`${username}= `, isUnique)
    setUsernameIsUnique(isUnique);
    return isUnique;
  }
  const handleModalSubmit = async (e: any) => {
    e.preventDefault();

    if (formSubmitting) return;
    if (!address) return;

    if (username.length < 2) {
      setFormError("Username must be 2 or more characters.");
      return;
    } else if (username.search(/[A-z0-9]+$/)) {
      setFormError("Username cannot contain any special characters.");
      return;
    } else if (username.length > 14) {
      setFormError("Username must be 14 characters or less.");
      return;

    } else if (!(await checkForUniqueness(username))) {
      // console.log('UNIQUNESS FAIL')
      setFormError("Username must be unique, try again.");
      return;
    }
    else if (!profileImage) {
      setFormError("Must select a profile image.");
      return;
    } else if (profileImage.size > 9000000) {
      setFormError("Image file size is too large.");
      return;
    } else {
      setFormError("");
    }

    const message = Date.now().toString();
    // const messageHash = EthCrypto.hash.keccak256("1");

    const signature = await sdk?.wallet.sign(message);

    // console.log(messageHash);
    //console.log(signature);

    setFormSubmitting(true);

    let formData = new FormData();
    formData.append("file", profileImage);

    const res = await fetch(`https://api.pinata.cloud/pinning/pinFileToIPFS`, {
      method: "POST",
      headers: {
        pinata_api_key: "fa13b7ac59970eb0a48a",
        pinata_secret_api_key:
          "74c1f5a76bd152fe7405d028089ac3ab76271f0b7505dc5a47ecd4bb4374112b",
      },
      body: formData,
    });

    let response = await res.json();
    //console.log(response);

    let imageUrl = "https://huddln.mypinata.cloud/ipfs/" + response.IpfsHash;

    let checksumAddress = ethers.utils.getAddress(address);
    // console.log("address: " + address);
    //console.log("checksum: " + checksumAddress);

    // let userRef = projectRealtimeDb
    //   .ref("/users_public/")
    //   .child(checksumAddress);

    let userData = {
      profileData: {
        userName: username,
        firstName: firstName.toLowerCase(),
        lastName: lastName.toLowerCase(),
        fullName: firstName.toLowerCase() + " " + lastName.toLowerCase(),
        // source: "xp",
        // isSetup: true,
        userImageURI: imageUrl,
        // createdAt: Date.now(),
      },
      profileSocials: { email },
      verification: { address, signature, message },
    };

    // await userRef.set(userData);

    //console.log(JSON.stringify(userData));

    let respHuddln = await fetch(
      "https://us-central1-huddl-in.cloudfunctions.net/createUserProfile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      }
    ).then((resp) => resp.json());

    //console.log('respHuddln', respHuddln)
    if (respHuddln.success && onSuccess) {
      onSuccess();
    }
    if (!respHuddln.success && onFailure) {
      onFailure();
    }

    //console.log(userData);
    //console.log(await respHuddln.json());

    setFormSubmitting(false);

    setUpdateProfileOpen(false);
  };

  useEffect(() => {
    const checkForPrefill = async () => {
      //console.log(address);
      await fetch(
        "https://us-central1-huddl-in.cloudfunctions.net/getProfile",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ address }),
        }
      ).then(async (res) => {
        let userProfile = await res.json();

        //console.log("PROFILE", userProfile);

        if (userProfile.profile) {
          userProfile = userProfile.profile;

          if (userProfile.userName) {
            setUsername(userProfile.userName);
            setUsernamePreLoaded(true);
          }
          if (userProfile.email) setEmail(userProfile.email);
          if (userProfile.firstName) setFirstName(userProfile.firstName);
          if (userProfile.lastName) setLastName(userProfile.lastName);
        }
      });
    };

    if (address) checkForPrefill();
  }, [address]);
  return (
    <div className="modal-backdrop" onClick={(e) => e.currentTarget === e.target && setUpdateProfileOpen(false)}
    >
      <div className="modal-container" >
        <img style={{ width: '100%', filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.4))', objectFit: 'cover', imageRendering: 'crisp-edges' }} src={BackgroundHeader}></img>
        <div className="close-modal">
          <div className="close-icon" onClick={() => setUpdateProfileOpen(false)}>
            <IoMdClose />
          </div>
        </div>
        <div className="modal-content" >
          <div className="modal-header">Update Profile</div>
          <form onSubmit={handleModalSubmit}>
            <div className="form-title">Username</div>
            <div>
              <input
                style={{
                  border: (!usernamePreLoaded && !usernameIsUnique) ? '2px solid rgb(220, 22, 22)' : 'none'
                }}

                type="text"
                placeholder="bobJohnson"
                value={username || ""}

                onChange={(e) => {

                  e.preventDefault();
                  e.target.value = e.target.value.toLowerCase();
                  if (!usernamePreLoaded) setUsername(e.target.value);
                  if (!usernamePreLoaded && e.currentTarget.value.length > 2) checkForUniqueness(e.target.value);
                }}
              />
              {!usernamePreLoaded && !usernameIsUnique &&
                <div style={{ marginBottom: 5, paddingLeft: 16, color: 'rgb(220, 22, 22)', fontFamily: 'SFProBold', fontSize: 12 }}>username is not unique</div>}
            </div>

            <div className="form-title">
              First Name<span className="optional"> (optional)</span>
            </div>
            <input
              type="text"
              placeholder="Bob"
              value={firstName || ""}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <div className="form-title">
              Last Name<span className="optional"> (optional)</span>
            </div>
            <input
              type="text"
              placeholder="Johnson"
              value={lastName || ""}
              onChange={(e) => setLastName(e.target.value)}
            />
            <div className="form-title">
              Email<span className="optional"> (optional)</span>
            </div>
            <input
              type="text"
              placeholder="example@email.com"
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="form-title">Profile Picture</div>
            <input
              type="file"
              placeholder="picture.png"
              className="choose-img"
              onChange={(e) => {
                if (e.target.files) setProfileImage(e.target.files[0]);
              }}
            />
            {formError && <div className="form-error">{formError}</div>}
            {formSubmitting ? (
              <button type="submit" className="modal-button">
                <div className="modal-button-content">
                  <img src={loader} />
                  Submitting...
                </div>
              </button>
            ) : (
              <button type="submit" className="modal-button">
                Update Profile
              </button>
            )}
          </form>
        </div>
      </div>
    </div >
  );
};

export default UpdateProfileModal;
