import "./RedemptionCard.css";

import { useEffect, useState } from 'react'
import DisplayNftType from '../DisplayNftType/DisplayNftType';
import { getMimeType } from '../../functions/myFunctions'

interface RenderProps {
  item: any;
  onClick: any;
  width: string;
  style?: any;
  padding?: string
}

const RedemptionCard = ({ item, onClick, width, style = {}, padding }: RenderProps) => {
  const [data, setData] = useState({});
  const [name, setName] = useState("");
  const [initAmount, setInitAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [tokenType, setTokenType] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [isPurchase, setIsPurchaseType] = useState(false);
  const [src, setSrc] = useState("");
  const [srcType, setSrcType] = useState("");
  const [mounted, setMounted] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setMounted(true)
    getNftData();
  }, []);

  const getNftData = async () => {
    // const address = "0x89082ea40fd815631ea6259af33b0af35258fb76";
    const address = item?.contractAddress;
    const response = await fetch(
      `https://polygon-mainnet.g.alchemy.com/nft/v2/83uAJ90PdMxTEMuwg3zIHj9ZG-CXD_rH/getNFTMetadata?contractAddress=${address}&tokenId=${item.token}&refreshCache=false`
    );

    const _d = await response.json();
    const { metadata, contractMetadata, contract, media } = _d;
    setData(_d);
    setName(metadata?.name);
    setIsPurchaseType(item?.requiresPurchase)
    // setBalance(contractMetadata?.totalSupply);
    setBalance(item?.amount);
    setInitAmount(item?.initialAmount);
    setTokenType(contractMetadata?.tokenType);
    setContractAddress(item?.contractAddress);

    const uri = metadata?.image?.startsWith("ar://")
      ? `https://arweave.net/${metadata?.image?.slice(5)}`
      : metadata?.imageUri?.startsWith("ipfs://")
        ? `https://huddln.mypinata.cloud/ipfs/${metadata?.image?.slice(7)}`
        : metadata?.image?.startsWith("Qm") ? `https://huddln.mypinata.cloud/ipfs/${metadata?.image}`
          : metadata?.image;


    setSrc(uri);
    const srcType = await getMimeType(uri);
    setSrcType(srcType?srcType:'dne');
    setLoaded(true)
  };

  return (
    <div className="redemption-card-outer" style={{ opacity: mounted ? 1 : 0, ...style }} >
      <div className='redemption-card' style={{ width, }} onClick={() => onClick(item?.id)} >
        <div className='redemption-card-img-contain'>
          <DisplayNftType
            type={srcType}
            src={src}
            height={width}
            width={width}
            borderRadius='10px'
            loaded={loaded}
          />
        </div>
        <div className='redemption-card-description' style={{ padding: padding ? padding : '20px' }}>
          <h1 className='redemption-card-description-title'>{name ? name : contractAddress}</h1>
          <h3>{isPurchase ? "Buy for" : "Minimum Score"}</h3>
          <h2>{item?.points} XP</h2>
          <h4 className='redemption-card-amount' style={{ color: balance <= 0 ? '#ccc' : '#BCBFF5' }}>
            {balance <= 0 ? `${initAmount} Edition${balance <= 0 ? '' : 's'} Sold Out!` : `Collected: ${initAmount - balance} of ${initAmount}`}
          </h4>
        </div>
      </div>
    </div>
  )
};


export default RedemptionCard