import { useEffect } from "react";
import { useParams, useSearchParams, useLocation } from "react-router-dom";

const DiscordAuth = () => {
  const location = useLocation();



  useEffect(() => {
    // console.log("target=",location.search.substring(6));
    window.opener.postMessage(
      { code: location.search.substring(6), target: "discord" },
      window.opener.location
    );
  }, [location]);
  return <div>Window will automatically close...</div>;
};

export default DiscordAuth;
