import {
  configureStore,
  applyMiddleware,
  combineReducers,
} from "@reduxjs/toolkit";
import CommunityReducer from "./reducers/CommunityReducer";
import thunk from "redux-thunk";

// const rootReducer = combineReducers({ AuthReducer, WalletReducer });

export const store = configureStore({
  reducer: {
    community: CommunityReducer
  }
})


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch