import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import RedemptionCard from '../../components/RedemptionCard/RedemptionCard';
import Lottie from "lottie-react";
import bonfire from "../../images/bonfire.json";
import Pagination from '../../components/Pagination/Pagination';
interface Props {
  projectId: any
}

export default function RedemptionTab({ projectId }: Props) {
  const navigate = useNavigate();

  const [project, setProject] = useState([])
  const [loaded, setLoaded] = useState(false)
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(9);

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const shownNfts = project.slice(firstPostIndex, lastPostIndex);

  const navTo = (id: any) => {
    navigate(
      `/community/${projectId}/${id}`
    );
  }

  useEffect(() => {
    getListed()
  }, [])

  const getListed = async () => {
    let { data } = await fetch(
      "https://api.thegraph.com/subgraphs/name/bcaklovi/huddln-redemptions-matic-v1",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
          query ($projectId: String!){
            redeemables(where:{ projectId: $projectId}, orderBy: createdAt, orderDirection: desc) {
              id
              redeemableId
              projectId
              name
              amount
              initialAmount
              points
              actionName
              scoreType
              requiresPurchase
              creator
              contractAddress
              createdAt
              token
            }
  }
        `,
          variables: {
            projectId,
          },
        }),
      }
    ).then((resp) => resp.json())
      .catch(err => console.log(err))
    if (data?.redeemables.length > 0) {
      setProject(data.redeemables);
    }
    setLoaded(true)
  };
  return (
    <div className='redemption-tab-contain'>
      {!loaded ? (
        <></>
      ) : project.length > 0 ? (
        <div className='redemption-tab-contain-inner-column'>
          {shownNfts.map((item: any) =>
            <RedemptionCard item={item} key={item?.id} onClick={navTo} style={{ marginBottom: 30 }} width='220px' />
          )}
        </div>
      ) : (
        <div className='redemption-tab-contain-inner-bon'>
          <Lottie
            animationData={bonfire}
            loop={true}
            style={{ height: 300 }}
          />
          <h3>No redemptions yet</h3>
        </div>
      )}
      <Pagination
        // total={allNftsLength}
        total={project.length}
        perPage={postPerPage}
        setPage={setCurrentPage}
        currentPage={currentPage}
        enableEnd={false}
        onEndReach={() => { }}
      />
    </div>
  )
}
