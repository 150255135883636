import { useAddress, useSDK } from "@thirdweb-dev/react";
import { useEffect, useState } from "react";
import { Routes, Route, useParams, useSearchParams } from 'react-router-dom';
import { projectRealtimeDb } from "../firebase/config";
import UpdateProfileModal from "../components/UpdateProfileModal";
import { firestoreGetUserInfoV4, truncate, firestoreCheckIfUsernameIsUniqueV2 } from "../utility";
import { useQuery, gql } from "@apollo/client";
import JSConfetti from 'js-confetti'
import MenuBar from "../components/MenuBar/MenuBar";
import "../styles/ConnectDiscord.css";
import Lottie from "lottie-react";
import CountUp from 'react-countup';
import { useNavigate } from "react-router-dom";

import hash from "hash.js";
import shining from '../images/shining.json'

import { FaDiscord } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import complete from '../images/complete.json'
import loader from "../images/loading3.gif";
import discordAnimation from '../images/discord2.json'
import goldStarAnimation from '../images/goldStar.json'
import { displayPartsToString } from "typescript";
type DiscordPageState = "initial" | "loading" | "success";

const jsConfetti = new JSConfetti()

const ConnectDiscord = () => {
  const sdk = useSDK();
  const address = useAddress();

  const [updateProfileOpen, setUpdateProfileOpen] = useState(false);
  const [code, setCode] = useState<string>();
  const [currentUser, setCurrentUser] = useState<any>();
  const [walletError, setWalletError] = useState<boolean>(false);
  const [page, setPage] = useState<DiscordPageState>("initial");
  const [projectId, setProjectId] = useState<any>();
  //project releated
  const [projectDescription, setProjectDescription] = useState<any>();
  const [logoURL, setLogoUrl] = useState<any>();
  const [backgroundUrl, setBackgroundUrl] = useState<any>();
  const [projectName, setProjectName] = useState<any>();
  const [queryParameters] = useSearchParams()
  const [userProfile, setUserProfile] = useState<any>('dne');
  const [xpAuthRewardEnabled, setXPAuthRewardEnabled] = useState<boolean>(false);
  const [xpProfileRewardEnabled, setXPProfileRewardEnabled] = useState<boolean>(false);
  const [xpProfileRewardAction, setXPProfileRewardAction] = useState<any>('dne');
  const [xpAuthRewardAction, setXPAuthRewardAction] = useState<any>('dne');
  const [currentUserScore, setCurrentUserScore] = useState<any>(0)
  const [hasReceivedProfileReward, setHasReceivedProfileReward] = useState<any>(false);
  const [hasReceivedAuthReward, setHasReceivedAuthReward] = useState<any>(true);
  const [confettiPop, setConfettiPop] = useState<boolean>(false);
  const [hideCompleteProfileButton, setHideCompleteProfileButton] = useState<any>(false)


  const navigate = useNavigate();



  const getActions = async (projectId: any) => {

    let { data } = await fetch('https://api.thegraph.com/subgraphs/name/bcaklovi/huddln-xp-mainnet', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        query getResults($projectId: String!) {
          projects(where: { id: $projectId }) {
            actions {
              name
              points
              direction
            }
          }
        }
        `,
        variables: {
          projectId,
        },
      }),
    }).then((resp) => resp.json());

    return data.projects[0].actions;
  };

  const getUserScore = async (address: any, scoreType: any, projectId: any) => {
    address = address.toLowerCase()
    try {
      let { data } = await fetch('https://api.thegraph.com/subgraphs/name/bcaklovi/huddln-xp-mainnet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
        query($address:String, $scoreType:String) {
          scores(where: {project: "${projectId}", scoreType: $scoreType, address: $address}) {
            id
            points
          }
        }
        `,
          variables: {
            address: address,
            scoreType,
          },
        }),
      }).then((resp) => resp.json());
      if (data.scores[0]) {

        return data.scores[0].points;
      }
      else {
        return 0;
      }
    }
    catch (err) {
      return 0;
    }
  }

  const checkIfUpdateIdExists = async (updateId: string) => {
    try {
      const updateIdHash = hash
        .sha256()
        .update(updateId)
        .digest("hex");
      //console.log('hash=', updateIdHash);

      let { data } = await fetch('https://api.thegraph.com/subgraphs/name/bcaklovi/huddln-xp-mainnet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
        query($id:String) {
          scoreUpdates(where: {id: $id}) {
            id
            pointChange
            scoreType
            address
            actionName
            createdAt
          }
        }
        `,
          variables: {
            id: '0x' + updateIdHash,
          },
        }),
      }).then((resp) => resp.json());
      // console.log('returend atat', data.scoreUpdates[0])
      if (data.scoreUpdates.length > 0) {

        return true;//score update exists}
      }
      else if (data.scoreUpdates.length == 0) return false;//score update does not
    } catch (err) {
      return false;//any failure say no does not exist.
    }
  }

  useEffect(() => {
    const onMount = async () => {
      let xpProfileRewardEnabled: boolean = false;
      let xpProfileRewardAction = 'dne';

      let xpAuthRewardEnabled: boolean = false;
      let xpAuthRewardAction = 'dne';
      //check to see if this community is offering xp for connecting discord
      //offer 1 auth with discord action name="xp:auth-discord"
      if (queryParameters.get("projectId")) {


        const projectId = queryParameters.get("projectId");
        const actions = await getActions(projectId);
        actions.forEach((action: any, i: number) => { //AUTH DISCORD REWARD

          if (action.name == 'xpOAuth:discord') { //'xpAuthDiscord') {
            xpAuthRewardEnabled = true;
            xpAuthRewardAction = actions[i];
          }
        });

        //  actions.forEach((action: any, i: number) => { //PROFILE REWARD
        //
        //  if (action.name == 'SocialParticipation') { //'xpProfile') {
        //    xpProfileRewardEnabled = true;
        //    xpProfileRewardAction = actions[i];
        //    }
        //   });

        setProjectId(projectId);
        setXPAuthRewardEnabled(xpAuthRewardEnabled);//ENABLE CONNECTION REWARD
        setXPAuthRewardAction(xpAuthRewardAction);

        setXPProfileRewardEnabled(xpProfileRewardEnabled);//ENABLE CONNECTION REWARD
        setXPProfileRewardAction(xpProfileRewardAction);
        //check if action exists

        //Only do this check if Address exists, and There exists a reward, no need to check otherwise

        if (address && xpAuthRewardEnabled && xpAuthRewardAction != 'dne') {

          const updateId = `${projectId}:xpOAuth:discord:social:${address}`.toLowerCase();
          // console.log('pre-', updateId)
          const userScore = await getUserScore(address, 'social', projectId);
          const hasReceivedAuthReward = await checkIfUpdateIdExists(updateId);
          const hasReceivedProfileReward = await checkIfUpdateIdExists(updateId);


          setHasReceivedAuthReward(hasReceivedAuthReward);
          setHasReceivedProfileReward(hasReceivedProfileReward);
          setCurrentUserScore(userScore)
        }
      }
    }

    onMount();
  }, [address])

  useAddress()

  const handleConnect = async () => {
    setWalletError(false);

    if (!address) {
      console.log("Wallet not connected");
      setWalletError(true);
      return;
    }
    // try and get user profile as well, so we can display if a CTA for user to register proifle
    let nativeProfile = 'dne';
    try {
      nativeProfile = await firestoreGetUserInfoV4(address);
      //onsole.log('userprofile', nativeProfile);
    } catch (err) {

    }
    setUserProfile(nativeProfile);

    const authUrl =
      "https://discord.com/api/oauth2/authorize?client_id=1002632424469057587&redirect_uri=https%3A%2F%2Fxp.huddln.io%2Fdiscord-connect%2Fauth&response_type=code&scope=identify%20guilds%20connections";

    const authPopup = window.open(authUrl, "", "width=320,height=800");

    window.addEventListener("message", (event) => {
      if (event.data.target == "discord") {
        authPopup?.close();
        // console.log(event.data);
        setCode(event.data.code);
        submitUserData("748944382111252581", event.data.code);
        // getUserToken(event.data.code);
      }
    });
    //setPage('success')

    //get current score
  };

  useEffect(() => {
    const getProjectDetails = async () => {
      if (projectId)
        await projectRealtimeDb
          .ref()
          .child("xp")
          .child("communities")
          .child(projectId)
          .once("value")
          .then((snapshot) => {
            if (snapshot.exists()) {
              // console.log(snapshot.val());
              let logoURL = snapshot.val().imageURI;
              let backgroundURL = snapshot.val().backgroundImageURI;
              let description = snapshot.val().description;
              let name = snapshot.val().name;
              if (logoURL) setLogoUrl(logoURL);
              if (backgroundURL) setBackgroundUrl(backgroundURL);
              if (description) setProjectDescription(description);
              if (name) setProjectName(name);

            }
          })
          .catch((err) => {
            console.log(err);
          });
    };
    if (projectId) {
      getProjectDetails();
    }
  }, [projectId])


  const getUserToken = async (authCode: string) => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("client_id", "1002632424469057587");
    urlencoded.append("client_secret", "DXswlfedhZnRV0QfR-Ikkbh5sZQWD9Gm");
    urlencoded.append("grant_type", "authorization_code");
    urlencoded.append("code", authCode);
    urlencoded.append(
      "redirect_uri",
      "https://leaderboards.huddln.io/discord-connect/auth"
    );

    //console.log(urlencoded);

    let tokenResponse = fetch("https://discord.com/api/oauth2/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: urlencoded,
    })
      .then((res) => {
        let data = res.json();
        return data;
      })
      .then((data) => {
        // console.log(data);
        getUser(data.access_token);
      })
      .catch((err) => {
        console.log(err);
      });

    // console.log(tokenResponse);
    // console.log(await tokenResponseData.body.json());
  };

  const getUser = async (token: string) => {
    const userResult = await fetch("https://discord.com/api/users/@me", {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    let user = await userResult.json();
    setCurrentUser(user);
    // console.log(user);

    submitUserData(user.id, token);
  };

  const submitUserData = async (userId: string, token: string) => {
    const OAuthData = {
      timeStamp: Date.now().toString(),
      userId,
      token,
    };
    // console.log(OAuthData);

    const message = hash
      .sha256()
      .update(JSON.stringify(OAuthData))
      .digest("hex");
    const signature = await sdk?.wallet.sign(message);

    setPage("loading");

    const data = {
      ...(queryParameters.get("projectId") && { xpProjectId: queryParameters.get("projectId") }),
      OAuthData: {
        timeStamp: Date.now().toString(),
        id: userId,
        token,
        platform: "discord",
        username: "username",
      },
      verification: {
        message,
        signature,
        address,
      },
    };

    // console.log(signature);

    let response = await fetch(
      "https://us-central1-huddl-in.cloudfunctions.net/verifyOauth",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    // console.log(response);

    let responeBody = await response.json();

    // console.log(responeBody);

    if (responeBody.success) {

      setPage("success");
    } else {
      setPage("initial");
      console.log("Failed.");
    }
  };

  /**
   * CONFETTI POP!
   */
  useEffect(() => {
    if (confettiPop) {
      jsConfetti.addConfetti({

        emojis: ['🦄', '💥', '🎉 '],

        emojiSize: 50,
        confettiNumber: 100,
      })
    }
  }, [confettiPop])

  return (
    <div className="discord-page">
      <div style={{ position: 'absolute', top: '10%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 50,


            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
      <div style={{ position: 'absolute', right: '10%', top: '10%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 50,

            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
      <div style={{ position: 'absolute', bottom: '10%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 50,


            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
      <div style={{ position: 'absolute', right: '10%', bottom: '10%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 50,

            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
      <div style={{ position: 'absolute', bottom: '10%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 50,

            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
      <div style={{ position: 'absolute', left: '0%', bottom: '50%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 50,

            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
      <div style={{ position: 'absolute', right: '10%', bottom: '50%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 50,

            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
      <div style={{ position: 'absolute', left: '30%', top: '20%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 100,

            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
      <div style={{ position: 'absolute', left: '40%', bottom: '15%', }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 100,

            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>

      <div className="discord-page-container" >



        <div className="discord-container"  >

          {backgroundUrl &&
            <img className="background-image" src={backgroundUrl} ></img>


          }
          {!!logoURL && !!projectName &&
            <div className="logo-box" style={{ marginLeft: '60px' }}>



              <div style={{ maxWidth: '200px', width: '200px', minWidth: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '14px' }}>

                <div style={{ display: 'flex', position: 'relative', borderRadius: '50%', height: '80px', width: '80px', border: '5px solid white', backgroundColor: '#5869E9', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.4))' }}>


                  <div className="discord-icon"  >
                    <FaDiscord />
                  </div>

                </div>


              </div>

              <div style={{
                marginRight: 120, filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.8))',
                maxWidth: '200px', width: '200px', minWidth: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              }}>
                <div style={{ position: 'relative', borderRadius: '50%', height: '80px', width: '80px', overflow: 'hidden', border: '5px solid white', backgroundColor: 'white', alignSelf: 'center' }}>

                  <img src={logoURL} style={{ height: '102%', width: '102%', backgroundColor: 'white', objectFit: 'cover' }}
                  //onClick={() => setImageZoomOpen(!imageZoomOpen)} 
                  />
                </div>


              </div>





            </div>
          }


          {!backgroundUrl &&
            <img className="background-image" src={"https://huddln.mypinata.cloud/ipfs/QmUKRJfQsa73hRnAEG67osHDZeYrVAfhX6tCcfhCqF3tJf"} ></img>


          }


          {!logoURL && !projectName &&
            <div className="logo-box"  >






              <div style={{ maxWidth: '200px', width: '200px', minWidth: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                <div style={{ filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.4))', display: 'flex', position: 'relative', borderRadius: '50%', height: '80px', width: '80px', border: '5px solid white', backgroundColor: '#5869E9', alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>


                  <div className="discord-icon" >
                    <FaDiscord />
                  </div>

                </div>


              </div>




            </div>
          }

          {!!projectName && page != 'loading' &&
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <div className="title gradient-earn-amount" style={{ fontSize: 20 }}>{'Earn XP on Discord'}</div>


              <div className="title" >{'from ' + projectName}</div>
            </div>
          }
          {!projectName && page != 'loading' &&
            <div className="title gradient-earn-amount" style={{ fontSize: 20 }}>{'Earn XP on Discord'}</div>
          }

          {page === "initial" && (

            < >
              <div className="discord-headline">Link a wallet to your Discord profile and earn XP accross all XP enabled discord communities</div>
              {xpAuthRewardEnabled && !hasReceivedAuthReward && !!xpAuthRewardAction?.points && //enabled and has not already redeemed reward
                <div style={{ marginTop: '.5em', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                  <div className='crop-anim' style={{ height: 30, width: 30, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>

                    <Lottie
                      animationData={goldStarAnimation}
                      loop={true}

                      style={{

                        width: 72,

                        filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5))',
                      }}
                    />
                  </div>
                  <div className="gradient-earn-amount">Earn: {xpAuthRewardAction.points}XP</div>
                  <span style={{ fontFamily: 'SFProBold', fontSize: '14px', color: 'white' }}>&nbsp; for connecting</span>
                </div>
              }
              {xpAuthRewardEnabled && hasReceivedAuthReward && !!xpAuthRewardAction?.points && //is enabled and already has redeemed
                <div style={{ marginTop: '.5em', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                  <div className='crop-anim' style={{ height: 30, width: 30, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>

                    <Lottie
                      animationData={goldStarAnimation}
                      loop={true}

                      style={{

                        width: 72,

                        filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5))',
                      }}
                    />
                  </div>
                  <div className="gradient-earn-amount">You have already redeemed this XP</div>
                </div>
              }


              {walletError && (
                <div className="error-message">Please connect your wallet by pressing "Connect Wallet".</div>
              )}
              <div className="buttons-box">
                <button
                  className="connect-discord-button"
                  onClick={handleConnect}
                >
                  Connect Discord
                </button>
              </div>
            </>
          )}
          {page === "loading" && (
            <>
              {/* <div className="discord-headline">Loading...</div> */}
              <button className="connect-discord-button" style={{ marginTop: 100, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                <div className="loader">
                  <img src={loader} />
                </div>
                Authorizing...
              </button>
            </>
          )}
          {page === "success" && (
            <Lottie
              animationData={complete}
              loop={false}
              style={{
                height: 100,
                filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5))',
              }}


            />)}
          {page === "success" && (
            <div className="discord-headline">
              Discord successfully connected!
            </div>
          )}
          {page == "success" //if XPEnabled Auth and it was sucecssfully redeemed, on page start, redeem status shoudl be false to ae able to get it
            && xpAuthRewardAction?.points
            && xpAuthRewardEnabled
            && !hasReceivedAuthReward
            &&
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 14 }}>
              <div className="your-xp">Your new XP: &nbsp;</div>
              <CountUp className='gradient-earn-amount'
                start={currentUserScore}
                end={Number(currentUserScore) + Number(xpAuthRewardAction.points)}
                duration={6}
                separator=","
                useEasing
                prefix=""
                onEnd={() => {
                  setConfettiPop(true);
                }}
                suffix=" XP" />
            </div>
          }



          {/* 
          HOLD OFF UNTIL WE HAVE MORE CAPITCITY TO DO THIS REWARD AS WELL.
          xpProfileRewardEnabled && !hasReceivedProfileReward && !!xpProfileRewardAction?.points && //enabled and has not already redeemed reward
            <div style={{ marginTop: '.5em', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
              <div className='crop-anim' style={{ height: 30, width: 30, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>

                <Lottie
                  animationData={goldStarAnimation}
                  loop={true}

                  style={{

                    width: 72,

                    filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5))',
                  }}
                />
              </div>
              <div className="gradient-earn-amount">Earn additional: {xpAuthRewardAction.points}XP</div>
              <span style={{ fontFamily: 'SFProBold', fontSize: '14px', color: 'white' }}>&nbsp; for completing your profile</span>
            </div>
          }
          {xpProfileRewardEnabled && hasReceivedProfileReward && !!xpProfileRewardAction?.points && //is enabled and already has redeemed
            <div style={{ marginTop: '.5em', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
              <div className='crop-anim' style={{ height: 30, width: 30, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>

                <Lottie
                  animationData={goldStarAnimation}
                  loop={true}

                  style={{

                    width: 72,

                    filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5))',
                  }}
                />
              </div>
              <div className="gradient-earn-amount">You have already redeemed this XP</div>
            </div>
                */}






          <div className="buttons-box">

            {page === "success" && (userProfile == 'dne' || !userProfile.userName) && !hideCompleteProfileButton && (
              <>


                <button
                  style={{ marginTop: 0 }}
                  className="connect-discord-button"
                  onClick={() => { setUpdateProfileOpen(true) }}
                >
                  Complete Profile
                </button>
              </>
            )}
            {page === "success" && (
              <>


                <button

                  className="close-window-button"
                  onClick={() => {

                    navigate(
                      `/`
                    );
                   }}
                >
                  Go Home
                </button>
              </>
            )}
          </div>
        </div>

      </div>
      <div style={{ color: "#424242", fontFamily: 'SFProBold', alignSelf: 'center' }}>From the Huddln team</div>
    

    </div>
  );
};

export default ConnectDiscord;
