import UserDetails from "../components/UserDetails";

const UserProfile = () => {
  return (
    <div className="user-profile-container" >
      <UserDetails />
    </div>
  );
};

export default UserProfile;
