import "./Pagination.css";

export default function Pagination({
  total,
  perPage,
  setPage,
  currentPage,
  enableEnd,
  onEndReach,
}: any) {
  let pages = [];
  const pageAmount = Math.ceil(total / perPage);

  for (let index = 0; index < pageAmount; index++) {
    pages.push(index + 1);
  }

  const paginate = (d: any) => {
    if (d === "more" && currentPage < pageAmount) {
      setPage(currentPage + 1);
    } else if (d === "less" && currentPage > 1) {
      setPage(currentPage - 1);
    }
  };

  const setPageHandler = (page: any) => {
    if (page > pageAmount - 3 && enableEnd === true) {
      setPage(page);
      onEndReach();
    } else {
      setPage(page);
    }
  };

  let min = 0;
  let max = 5;

  if (currentPage > 2) {
    min = currentPage - 3;
    max = currentPage + 2;
  }

  let showPages = pages.slice(min, max);

  if (showPages.length > 0) {
    return (
      <div className="pagination-container"
      >
        <h2
          className="pagination-txt"
          onClick={() => paginate("less")}
        >
          {"<"}
        </h2>
        {showPages.length > 0 ? (
          showPages.map((page) => (
            <h3
              key={page}
              onClick={() => setPageHandler(page)}
              className="pagination-txt"
              style={{ color: currentPage === page ? "white" : "#bbb" }}
            >
              {page}
            </h3>
          ))
        ) : (
          <h3
            className="pagination-txt"
          >
            0
          </h3>
        )}
        <h2
          onClick={() => paginate("more")}
          className="pagination-txt"
        >
          {">"}
        </h2>
      </div>
    );
  } else {
    return <></>
  }
}
