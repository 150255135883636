import { useState } from "react";
import { capitalize } from "../../functions/myFunctions";
import ScoreTab from "../../components/ScoreTab";

type ProjectScoresProps = {
  scoreTypes: any[];
  scoreTabs: any[];
  projectId: any;
};

const ProjectScores = ({ scoreTypes, scoreTabs, projectId }: ProjectScoresProps) => {
  const [currentScoreType, setCurrentScoreType] = useState<string>("all");

  return (
    <div className="leaderboard-container" >
      <p>Filter by Score Types</p>
      <div className="leaderboard-tabs-container">
        <div
          className={`leaderboard-tab ${currentScoreType === 'all' ? 'active' : 'not-active'}`}
          onClick={() => setCurrentScoreType('all')}
        >
          {capitalize('all')}
        </div>
        {scoreTypes &&
          scoreTypes.map((type, index) => (
            <div
              key={index}
              className={`leaderboard-tab ${currentScoreType === type ? 'active' : 'not-active'}`}
              onClick={() => setCurrentScoreType(type)}
            >
              {capitalize(type)}
            </div>
          ))}
      </div>
      {scoreTabs &&
        scoreTabs.map((type, index) => (
          <ScoreTab
            key={index}
            projectId={projectId}
            scoreType={type}
            open={currentScoreType === type ? true : false}
          />
        ))}
    </div>
  );
};

export default ProjectScores;
