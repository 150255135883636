import "./Tabs.css";

import React from 'react'

interface Props {
  labels: string[];
  currentTab: number;
  setCurrentTab: any;
}

export default function Tabs({ labels, currentTab, setCurrentTab }: Props) {
  return (
    <div className='tab-container'>{labels.map((item, i) => (
      <div className={`tab ${currentTab === i ? 'active' : ''}`} key={item} onClick={() => {
        setCurrentTab(i)

      }
      }>
        {item}
        </div>
    ))}
      <div className='tab-bar' style={{
        transform: `translateX(${140 * currentTab}px)`
      }} />
    </div>
  )
}
