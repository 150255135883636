import "./Home.css";
import React from "react";
import { useEffect, useState } from "react"
import { gql, useQuery, useApolloClient } from "@apollo/client";
import { GET_COMMUNITY_MEMBERS } from "../../queries";
import { abbreviateNumber } from "../../functions/myFunctions";

import defaultAvatar from '../../images/avatar23.png'
import defaultBg from '../../images/defaultbg.png'
import verifiedPurpleImg from "../../images/verificationPurple.png";
import verifiedBlueImg from "../../images/verificationBlue.png";

const HomeCard = ({ item, navToCommunity }: any) => {
  const [totalMembers, setTotalMembers] = useState(0)
  const [totalXP, setTotalXp] = useState(0)

  const verificationImg = item?.verification === '1' ?
    verifiedBlueImg :
    item?.verification === '2' ? verifiedPurpleImg : null

  const { data } = useQuery(GET_COMMUNITY_MEMBERS, {
    variables: { id: item.xpId },
  });

  useEffect(() => {
    if (data?.project[0]) {
      setTotalMembers(data.project[0].totalUsers)
      setTotalXp(data.project[0].projectTotalScore)
    }

  }, [data])


  return (

    // <div className="home-card-wrapper">
    <div className='home-card hovered' onClick={() => navToCommunity(item?.xpId)}>
      <img
        src={item?.backgroundImageURI}
        className="home-card-bg"
        alt="missing"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = defaultBg
        }}
      />
      <div className="home-card-content">
        <div className="home-card-image" >
          <img
            src={item?.imageURI}
            alt="missing"
            className="home-card-image-inner"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = defaultAvatar
            }}
          />
          <div style={{
            boxShadow: "0px 0px 10px 3px rgba(0, 0, 0, .75)",
            right: 6, top: 5, height: 14, width: 14, backgroundColor: 'transparent', position: 'absolute'
          }}></div>
          {verificationImg && (
            <img
              src={verificationImg}
              alt=""
              height={25}
              width={25}
              style={{
                borderRadius: 12.5,
                position: 'absolute',
                right: 0,
                top: 0
              }}
            />
          )}


        </div>
        <div className="home-card-content-right">
          <h2>{item?.name}</h2>
          <p>{item?.description}</p>
          <div className="home-card-content-right-xp">
            <h5 className="home-gradient-members"><span className="home-gradient">{abbreviateNumber(totalMembers)}</span>  Members</h5>
            <h5><span className="home-gradient">{abbreviateNumber(totalXP)}</span> XP Rewarded</h5>
          </div>
        </div>
      </div>
    </div>
    // </div>
  )
}

export default React.memo(HomeCard);