import './DisplayNftType.css'

import missingImage from "../../images/img_missing.png";
import IframeResizer from "iframe-resizer-react";
import ReactPlayer from 'react-player';

export default function DisplayNftType({ type, src, height, width, autoPlay, loaded, objectFit = "cover", disableControls = true }: any) {
  if (!loaded) {
    return <div
      style={{
        width,
        height,
      }}
      className='shimmer' />
  } else if (type == 'image' || !src) {
    return (
      <img
        key={src}
        style={{
          width,
          height,
          objectFit,
        }}
        src={src ? src : missingImage}
        alt=""
      />
    );
  } else if (type == 'audio') {
    return <audio
      key={src}
      style={{
        height,
        width,
      }}
      controls
      src={src}
    />;
  } else if (type == "video") {
    return (
      <div style={{
        // objectFit,
        height,
        width,
        overflow: 'hidden'
      }}>
        <video
          key={src}
          preload="metadata"
          style={{
            objectFit,
            height,
            width,
          }}
          disablePictureInPicture
          disableRemotePlayback
          controlsList='nodownload'
          controls={!disableControls}
        >
          <source src={`${src}#t=2`} type="video/mp4" />
        </video>
      </div>
    );
  } else if (type == "dne" && src?.includes('http')) {
    return (
      <IframeResizer
        key={src}
        // sandbox
        heightCalculationMethod="lowestElement"
        src={src}
        style={{
          width,
          height,
        }}
      />
    );
  }
  else {
    return (
      <div style={{
        display: 'flex', flex: 1, width,
        height, justifyContent: 'center', alignItems: 'center'
      }}>
        <img
          style={{
            width: 50,
            height: 50,
            objectFit,
            alignSelf: 'center'
          }}
          src={missingImage}
          alt=""
        />
      </div>
    );
  }
}
