import './DropDown.css'

import React, { useEffect, useRef, useState } from 'react'
import { HiChevronDown } from "react-icons/hi";
interface Props {
  currentItem: String | null;
  setCurrentItem: React.Dispatch<any>;
  list: any[];
}

const DropDown = ({
  currentItem = null,
  setCurrentItem,
  list
}: Props) => {
  const [open, setOpen] = useState(false)
  const dropDownRef = useRef<HTMLDivElement>(null);
  const dropDownRefContainer = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setOpen(!open)
  }

  const selectItem = (name: String) => {
    setCurrentItem(name)
    setOpen(false)
  }

  useEffect(() => {
    window.addEventListener('click', handleClick, false)
    return () => window.removeEventListener('click', handleClick, false)
  }, [])


  const handleClick = (e: any) => {
    // if (e.target !== dropDownRef.current && open) setOpen(false)
    if (dropDownRefContainer.current && !dropDownRefContainer.current.contains(e.target)) {
      setOpen(false)
    }
  }

  const cn1 = open ? "toggleOut" : "toggleIn"

  return (
    <div className='drop-down-container' onClick={toggleOpen} ref={dropDownRefContainer} >
      <div className='drop-down-title-container' ref={dropDownRef}>
        <h3 className='drop-down-title'>{currentItem ? currentItem : 'Sort By'}</h3>
        <HiChevronDown width={15} height={15} color='white'
          // style={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
          className={cn1}
        />
      </div>
      {open && <div className="drop-down-list">
        {list.map((item) => (
          <li className="drop-down-item" onClick={() => selectItem(item.name)} key={item.name}>
            <img src={item.logo} height={15} width={15} alt='' />
            {item.name}
          </li>
        ))}
      </div>
      }
    </div >
  )
}


export default React.memo(DropDown)