import { gql } from "@apollo/client";
import { apolloClient } from ".";

export const GET_PROJECT_REDEEMABLES = gql`
  query getProjectRedeemables($projectId: String!) {
    redeemables(where: { projectId: $projectId }) {
      id
      redeemableId
      projectId
      amount
      points
      requiresPurchase
      scoreType
    }
  }
`;

export const GET_PROJECT = gql`
  query getResults($projectId: String!) {
    projects(where: { id: $projectId }) {
      scoreTypes
      totalUsers
      projectTotalScore
    }
  }
`;

export const GET_ROWS_USER = gql`
query getResults(
  $projectId: String!
  $address: String!
  $scoreTypeName: String!
) {
  scores(
    where: { project: $projectId, scoreType: $scoreTypeName, address:$address }
    orderBy: points
    orderDirection: desc
  ) {
    id
    address
    points
    scoreType
  }
}
`;

export const GET_ROWS_USER_ALL = gql`
query getResults(
  $projectId: String!
  $address: String!
) {
  scores(
    where: { project: $projectId, address:$address }
    orderBy: points
    orderDirection: desc
  ) {
    id
    address
    points
    scoreType
  }
}
`;

export const GET_ROWS = gql`
  query getResults(
    $projectId: String!
    $scoreTypeName: String!
    $first: Int!
    $skip: Int!
  ) {
    scores(
      first: $first
      skip: $skip
      where: { project: $projectId, scoreType: $scoreTypeName }
      orderBy: points
      orderDirection: desc
    ) {
      id
      address
      points
      scoreType
    }
  }
`;

export const GET_USER_XP_BY_TYPE = gql`
query($project:String, $address:String, $scoreType:String) {
  scores(where: {project: $project, scoreType: $scoreType, address: $address}) {
    id
    points
  }
}
`;

export const GET_ROWS_ALL = gql`
  query getResults($projectId: String!, $first: Int!, $skip: Int!) {
    scoreboards(
      first: $first
      skip: $skip
      where: { project: $projectId }
      orderBy: totalScore
      orderDirection: desc
    ) {
      id
      address
      totalScore
    }
  }
`;

export const CHECK_USER_EARNED = gql`
{
     earnedRedeemables(where: {id: "0xbb64da060955b48b04f523c2caab39d00d2b6ce20b48a4ece60009263d5b1e6b_0x1d703fbff335a7f953411c8ec7745c2aaec56a31"}) {
    id
  }
} 
`;

export const GET_COMMUNITY_MEMBERS = gql`
  query getResults($id: String!) {
    project: projects(where: { id: $id }) {
      name
      totalUsers
      projectTotalScore
    }
  }
`;

export const GET_MY_PROJECTS = gql`
  query getProjects($user: String!) {
    projects(where: { owners_contains: [$user] }) {
      id
      name
      owners
      totalUsers
      projectTotalScore
    }
  }
`;

export const GET_USER_COMMUNITIES = gql`
  query getUserProjects($address: String!) {
    scoreboards(where: { address: $address }) {
      project {
        id
        name
      }
    }
  }
`;

export const GET_SCORE_UPDATES = gql`
  query getScoreUpdates(
    $projectId: String!
    $address: String!
    $first: Int!
    $skip: Int!
  ) {
    scoreUpdates(
      first: $first
      skip: $skip
      where: { project: $projectId, address: $address }
      orderBy: createdAt
      orderDirection: desc
    ) {
      actionName
      pointChange
      direction
      scoreType
      createdAt
    }
  }
`;

export const GET_TOTAL_SCORE = gql`
  query getTotalScore($projectId: String!, $address: String!) {
    scoreboards(where: { project: $projectId, address: $address }) {
      totalScore
      scores {
        scoreType
        points
      }
    }
  }
`;

export const GET_SCORE_TYPE = gql`
  query getTotalScore($projectId: String!, $address: String!) {
    scores(where: { project: $projectId, address: $address }) {
      scoreType
      points
    }
  }
`;

export const GET_COMMUNITIES_BY_CREATED_DATE = gql`
  {
    projects(orderBy: createdAt, orderDirection: desc) {
      id
      name
      createdAt
    }
  }
`;

const getCommunityRankingCombineQuery = `
query($id:String ,$number:Int) {
  project:projects(where: {id:$id}) {
    name
    totalUsers
    projectTotalScore
    createdAt
  }
  projectSevenDaysAgo: projects(block: {number: $number}, where: {id:$id}) {
    name
    totalUsers
    projectTotalScore
    createdAt
  }
}`;

export const getCommunityRankingCombine = async (id = "0") => {
  const idLower = id.toLowerCase();
  let obj = {
    projectTotalScore: 0,
    totalUsers: 0,
    userIncreasePercent: 0,
  };

  if (id) {
    try {
      const { data } = await apolloClient.query({
        query: gql(getCommunityRankingCombineQuery),
        variables: {
          id: idLower,
          number: 32685650,
        },
      });
      if (data) {
        const { project, projectSevenDaysAgo } = data;

        // let obj = {};

        if (project[0] && projectSevenDaysAgo[0]) {
          const userIncreasePercent =
            (Number(project[0].totalUsers) /
              Number(projectSevenDaysAgo[0].totalUsers)) *
            10;

          obj = {
            ...project[0],
            userIncreasePercent,
            id,
          };

          return obj;
        } else if (project[0]) {
          const userIncreasePercent = Number(project[0].totalUsers);
          obj = {
            ...project[0],
            userIncreasePercent,
            id,
          };

          return obj;
        } else {
          return obj;
        }
      }
    } catch (error) {
      console.log("error: " + error);
      // const obj = {};
      return obj;
    }
  } else {
    return {};
  }
};
