import "./StarBG.css";

import Lottie from "lottie-react";
import shining from '../../images/shining.json'

export default function StarBG() {
  return (
    <>
      <div style={{ position: 'absolute', top: '10%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 50,


            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
      <div style={{ position: 'absolute', right: '10%', top: '10%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 50,

            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
      <div style={{ position: 'absolute', bottom: '10%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 50,


            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
      <div style={{ position: 'absolute', right: '10%', bottom: '10%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 50,

            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
      <div style={{ position: 'absolute', bottom: '10%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 50,

            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
      <div style={{ position: 'absolute', left: '0%', bottom: '50%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 50,

            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
      <div style={{ position: 'absolute', right: '10%', bottom: '50%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 50,

            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>

      <div style={{ position: 'absolute', left: '30%', top: '20%' }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 100,

            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
      <div style={{ position: 'absolute', left: '40%', bottom: '15%', }}>
        <Lottie
          animationData={shining}
          loop={true}
          style={{
            height: 100,

            filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2))',
          }}
        />
      </div>
    </>
  )
}
