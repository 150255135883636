import {
  SET_ALL_VERIFIED_COMMUNITIES,
  SET_PROJECT_SHOW_TYPE,
  GET_USER_COMMUNITIES_REDUX,
  GET_USER_PROJECTS_REDUX,
} from "../actions/CommunityActions";

interface StateProps {
  projectShowType: string;
  allVerifiedCommunities: any
  userCommunities: any
  userProjects: any
}

const initialState: StateProps = {
  projectShowType: 'explore',
  allVerifiedCommunities: [],
  userCommunities: [],
  userProjects: []
};

interface ReducerProps {
  type: String
  payload: StateProps
}

const reducer = (state = initialState, { type, payload }: any) => {
  switch (type) {

    case SET_ALL_VERIFIED_COMMUNITIES:
      const { allVerifiedCommunities } = payload;
      return {
        ...state, allVerifiedCommunities
      };

    case SET_PROJECT_SHOW_TYPE:
      const { projectShowType } = payload
      return { ...state, projectShowType };

    case GET_USER_COMMUNITIES_REDUX:
      const { userCommunities } = payload
      return { ...state, userCommunities };

    case GET_USER_PROJECTS_REDUX:
      const { userProjects } = payload
      return { ...state, userProjects };
    default:
      return state;
  }
};

export default reducer;
