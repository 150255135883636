const getUserScore = async (address: any, scoreType: any, projectId: any) => {
  address = address.toLowerCase()
  try {
    let { data } = await fetch('https://api.thegraph.com/subgraphs/name/bcaklovi/huddln-xp-mainnet', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
      query($address:String, $scoreType:String) {
        scores(where: {project: "${projectId}", scoreType: $scoreType, address: $address}) {
          id
          points
        }
      }
      `,
        variables: {
          address: address,
          scoreType,
        },
      }),
    }).then((resp) => resp.json());
    if (data.scores[0]) {
      return data.scores[0].points;
    }
    else {
      return 0;
    }
  }
  catch (err) {
    return 0;
  }
}

const funcs = {
  getUserScore
}

export default funcs