import { projectRealtimeDb } from "../firebase/config";


export const getCommunitiesFb = async (callback: any) => {
  try {
    await projectRealtimeDb
      .ref("/xp/communities")
      .on("value", (snapshot) => {
        callback(snapshot.val())
      })
  } catch (error) {
    console.log("error: " + error)
  }
}


export const getOneCommunityFb = async (xpId: any) => {
  const snapshot = await projectRealtimeDb
    .ref("/xp/communities")
    .child(xpId).get()

  if (snapshot.exists()) {
    return snapshot.val()
  }
}
