import "./SidebarComponent.css";

import { Sidebar, Menu, MenuItem, useProSidebar, sidebarClasses, SubMenu } from 'react-pro-sidebar';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import MenuComponent from "./MenuComponent";
import { FaSearch, FaHome, FaHeart, FaRegCompass } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import { useDispatch } from "react-redux";
import { setProjectShowType } from "../../redux/actions/CommunityActions";
import verified from '../../images/purple-check.png'
import verified_white from '../../images/white-check.png'

const SubMenuStyles = {
  fontFamily: 'SFProHeavy',
  fontWeight: 800,
  fontSize: 16,
  // color: '#B8BED3',
}

function SidebarComponent() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { collapseSidebar, toggleSidebar, broken, rtl } =
    useProSidebar();


  const [selected, setSelected] = useState('verified')
  const [selectedTab, setSelectedTab] = useState('')

  const { userCommunities, userProjects } = useSelector((state: RootState) => state.community);

  const selectSideBarMode = (mode: any) => {
    setSelected(mode)
    dispatch(setProjectShowType(mode))
  }

  return (
    <Sidebar
      id='#side-bar'
      width='325px'
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: '#262626',
          paddingTop: broken ? '40px' : '100px',
          boxShadow: "5px 20px 20px rgba(0,0,0,.3)"
        },
      }}
      style={{
        height: '100vh',
        // height: 'calc(100vh - 60px)',
        zIndex: broken ? 1000 : 500,
        position: broken ? 'fixed' : 'fixed',
        border: 0,
        top: 0,
      }}
      breakPoint='xl'
      transitionDuration={800}
      defaultCollapsed={false}
    >
      <Menu
        style={{
          minHeight: "calc(100% - 240px)",
          marginBottom: "10px"
        }}
        menuItemStyles={{
          button: {
            '&:hover': {
              backgroundColor: '#181818',
              color: 'white'
            },
          },
        }}
      >
        <MenuItem
          active={true}
          onClick={() => selectSideBarMode('verified')}
          icon={
            <img src={selected === 'verified' ? verified_white : verified} alt=''
              style={{
                height: 16,
                width: 16,
              }}
            />
          }
          style={{ textAlign: "left" }}
          component={<Link to="/" />}>
          <p className='side-bar-title' style={{
            color: selected === 'verified' ? 'white' : "#C6C7FF"
          }}>Verified Communities</p>
        </MenuItem>
        <SubMenu
          label='My Communities'
          onClick={() => selectSideBarMode('community')}
          rootStyles={{
            ...SubMenuStyles,
            color: selected === 'community' ? 'white' : "#C6C7FF"
          }}
          icon={<FaHome size={16} />}>
          {!!userCommunities?.scoreboards && userCommunities?.scoreboards.map((item: any) => <MenuComponent
            item={item}
            key={item?.project?.id}
            community={true}
            parentSelected={selected === 'community' ? true : false}
            setSelected={setSelected}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          )}
        </SubMenu>
        <SubMenu
          label='My Projects'
          onClick={() => selectSideBarMode('project')}
          rootStyles={{
            ...SubMenuStyles,
            color: selected === 'project' ? 'white' : "#C6C7FF"
          }}
          icon={<FaHeart size={16} />}>
          {!!userProjects?.projects && userProjects?.projects.map((item: any) => < MenuComponent
            item={item}
            key={item.id}
          />)}
        </SubMenu>
      </Menu>
      <div style={{ position: 'relative', alignSelf: 'flex-end', height: "230px" }}>
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignContent: 'center', marginBottom: "20px" }}
          onClick={() => {
            navigate(
              `/discord-connect`
            );
          }}
        >
          <img
            className="social-img"
            src={require('../../images/glasspanel.png')}
          />
          <div className="glass-panel" >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <img
                className="discord-logo"
                src={require('../../images/discord.png')}
              />
              <div className="social-text">Connect your discord</div>
            </div>
            <div style={{ display: 'flex', marginTop: 2, flexDirection: 'row', alignItems: 'center' }}>
              <div className="social-text" style={{ fontSize: '12px' }}>To earn from XP enabled Discord servers</div>

            </div>

          </div>
        </div>
        <div style={{ color: "#8B8F9B", fontFamily: 'SFProBold', textAlign: 'center', bottom: 40, left: 0, right: 0, width: '100%' }}>
          XP Discovery App
        </div>
        <div style={{ color: "#8B8F9B", fontFamily: 'SFProBold', textAlign: 'center', bottom: 20, left: 0, right: 0, width: '100%' }}>
          From the Huddln team
        </div>

        <div style={{ color: "#8B8F9B", fontFamily: 'SFProBold', textAlign: 'center', fontSize: 12, bottom: 5, left: 0, right: 0, width: '100%' }}>
          v0.6.2 Alpha
        </div>
      </div>
    </Sidebar >
  );
}

export default SidebarComponent